<template>
<div id="app">
  <div v-if="initializationError" class="error-message">
    {{ initializationError }}
    <button @click="retryInitialization" class="retry-button">Retry</button>
  </div>
  <template v-else>
    <Header v-if="!isHiddenRoute" />
    <main class="main-content">
      <router-view />
    </main>
    <Footer v-if="!isHiddenRoute"
      :liveTimecode="liveTimecode"
      :currentTimeSourceLabel="currentTimeSourceLabel"
      :localStorageUsage="localStorageUsage"
      :userEmail="userEmail"
      :isAdmin="isAdmin"
      @clearCache="clearCache"
      @signOut="signOut"
    />
  </template>
</div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from './stores/userStore';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import { useToast } from 'vue-toastification';

export default {
components: {
  Header,
  Footer,
},
setup() {
  const userStore = useUserStore();
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();

  const initializationError = ref(null);
  const appVersion = '9.7';
  const userEmail = computed(() => userStore.getUserEmail);
  const isAdmin = computed(() => userStore.isAdmin);
  const onlineStatus = ref(navigator.onLine);
  const onlineStatusText = computed(() => (onlineStatus.value ? 'Online' : 'Offline'));
  const onlineStatusClass = computed(() => ({
    'online': onlineStatus.value,
    'offline': !onlineStatus.value,
  }));

  const liveTimecode = computed(() => userStore.getLiveTimecode);
  const currentTimeSourceLabel = computed(() => userStore.getCurrentTimeSourceLabel);

  const localStorageUsage = ref({
    used: 0,
    max: 5120, // 5MB in KB
  });

  const isHiddenRoute = computed(() => {
    const hiddenRoutes = ['/', '/auth/reset-password', '/auth/set-password'];
    return hiddenRoutes.includes(route.path);
  });

  const calculateLocalStorageUsage = () => {
    let total = 0;
    for (const key in localStorage) {
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        const value = localStorage.getItem(key);
        total += key.length + value.length;
      }
    }
    localStorageUsage.value.used = (total / 1024).toFixed(2);
  };

  const clearCache = () => {
    localStorage.clear();
    calculateLocalStorageUsage();
    toast.success('Cache cleared successfully!');
  };

  const signOut = async () => {
    try {
      await userStore.signOut();
      router.push('/');
    } catch (error) {
      console.error('Error during sign-out:', error.message);
      toast.error('Error during sign-out. Please try again.');
    }
  };

  const retryInitialization = async () => {
    initializationError.value = null;
    try {
      await userStore.initializeStore();
    } catch (error) {
      initializationError.value = `Failed to initialize the app: ${error.message}`;
    }
  };

  const updateOnlineStatus = () => onlineStatus.value = navigator.onLine;

  onMounted(async () => {
    try {
      await userStore.initializeStore();
      calculateLocalStorageUsage();
      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
    } catch (error) {
      initializationError.value = `Failed to initialize the app: ${error.message}`;
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  });

  return {
    userEmail,
    isAdmin,
    signOut,
    onlineStatusText,
    onlineStatusClass,
    liveTimecode,
    currentTimeSourceLabel,
    localStorageUsage,
    clearCache,
    appVersion,
    isHiddenRoute,
    initializationError,
    retryInitialization,
  };
},
};
</script>

<style scoped>
/* ... (existing styles) */

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.retry-button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #007bff;
  color: white;
}

.retry-button:hover {
  background-color: #0056b3;
}
</style>