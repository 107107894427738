import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';

// Import Vue Toastification and its CSS
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import the user store to load session on app start
import { useUserStore } from './stores/userStore';

// Optional: Customize default toast options
const toastOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

const app = createApp(App);
const pinia = createPinia();

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

// Use Pinia and router in the app
app.use(pinia);
app.use(router);

// Use Toastification plugin with the specified options
app.use(Toast, toastOptions);

// Initialize the user store
const userStore = useUserStore(pinia);

// Mount the app
app.mount('#app');

// Add offline/online event listeners
window.addEventListener('online', () => {
  console.log('App is online');
  userStore.syncOfflineData(); // Sync any offline data when coming back online
});

window.addEventListener('offline', () => {
  console.log('App is offline');
  // You can add any offline-specific logic here
});

// Optional: Add an event listener for the 'updatefound' event on the service worker registration
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.addEventListener('updatefound', () => {
      const newWorker = registration.installing;
      newWorker.addEventListener('statechange', () => {
        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
          // New service worker is installed and ready to take over
          // You can show a notification to the user here if you want to prompt for a refresh
          console.log('New service worker installed. App update available.');
        }
      });
    });
  });
}