import { defineStore } from 'pinia';
import { supabase } from '../supabase';
import CryptoJS from 'crypto-js';
import { openDB } from 'idb';

const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

if (!ENCRYPTION_KEY) {
  throw new Error('Encryption key not set in environment variables. Please set VUE_APP_ENCRYPTION_KEY.');
}

const SESSION_EXPIRATION_TIME = 3600000; // 1 hour in milliseconds

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: null,
    userEmail: null,
    currentProject: null,
    liveTimecode: localStorage.getItem('liveTimecode') || '00:00:00',
    contacts: [],
    isLoading: false,
    isSubmitting: false,
    currentTimeSource: localStorage.getItem('currentTimeSource') || 'device',
    onlineStatus: navigator.onLine,
    _intervalId: null,
    isInitialized: false,
    db: null,
  }),

  actions: {
    async initializeStore() {
      if (this.isInitialized) return;
      try {
        await this.fetchUserSession();
        await this.initDB();
        await this.loadProjectFromLocalStorage();
        this.isInitialized = true;
      } catch (error) {
        console.error('Failed to initialize store:', error);
        throw error;
      }
    },

    async initDB() {
      if (this.db) return this.db;
      
      try {
        this.db = await openDB('UserStore', 2, {
          upgrade(db, oldVersion, newVersion, transaction) {
            if (!db.objectStoreNames.contains('session')) {
              db.createObjectStore('session');
            }
            if (!db.objectStoreNames.contains('project')) {
              db.createObjectStore('project');
            }
            if (!db.objectStoreNames.contains('contacts')) {
              db.createObjectStore('contacts', { keyPath: 'id' });
            }
          },
        });
        return this.db;
      } catch (error) {
        console.error('Failed to initialize IndexedDB:', error);
        return null;
      }
    },

    initializeOnlineStatus() {
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
    },

    updateOnlineStatus() {
      this.onlineStatus = navigator.onLine;
      console.log(`Online status updated: ${this.onlineStatus ? 'Online' : 'Offline'}`);
    },

    cleanupOnlineStatus() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
    },

    encryptData(data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    },

    decryptData(encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        console.error('Failed to decrypt data:', e);
        return null;
      }
    },

    async saveSessionToLocalStorage(userData) {
      const sessionData = {
        user: this.encryptData(userData),
        timestamp: new Date().getTime(),
      };
      
      localStorage.setItem('userSession', JSON.stringify(sessionData));
      
      // Backup to IndexedDB
      if (this.db) {
        try {
          await this.db.put('session', sessionData, 'userSession');
        } catch (error) {
          console.error('Failed to backup session to IndexedDB:', error);
        }
      }
    },

    async loadSessionFromLocalStorage() {
      let sessionData = JSON.parse(localStorage.getItem('userSession'));

      if (!sessionData && this.db) {
        try {
          sessionData = await this.db.get('session', 'userSession');
        } catch (error) {
          console.error('Failed to load session from IndexedDB:', error);
        }
      }

      if (!sessionData) return null;

      const currentTime = new Date().getTime();

      if (currentTime - sessionData.timestamp > SESSION_EXPIRATION_TIME) {
        await this.clearSession();
        console.log('Session expired. User has been logged out.');
        return null;
      }

      const decryptedUser = this.decryptData(sessionData.user);
      this.user = decryptedUser;
      return decryptedUser;
    },

    async saveProjectToLocalStorage(projectData) {
      const encryptedData = this.encryptData(projectData);
      localStorage.setItem('currentProject', encryptedData);
      
      // Backup to IndexedDB
      if (this.db) {
        try {
          await this.db.put('project', encryptedData, 'currentProject');
        } catch (error) {
          console.error('Failed to backup project to IndexedDB:', error);
        }
      }
    },

    async loadProjectFromLocalStorage() {
      let encryptedProject = localStorage.getItem('currentProject');

      if (!encryptedProject && this.db) {
        try {
          encryptedProject = await this.db.get('project', 'currentProject');
        } catch (error) {
          console.error('Failed to load project from IndexedDB:', error);
        }
      }

      if (encryptedProject) {
        this.currentProject = this.decryptData(encryptedProject);
      }
    },

    async fetchUserSession() {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) {
          console.error('Error fetching session:', error.message);
          return null;
        }

        if (session) {
          this.user = session.user;
          this.userEmail = session.user.email;
          await this.saveSessionToLocalStorage(session.user);
          return this.user;
        } else {
          this.clearSession();
          return null;
        }
      } catch (error) {
        console.error('Error fetching user session:', error.message);
        this.clearSession();
        return null;
      }
    },

    async checkProjectMember(email, projectId) {
      try {
        const { data, error } = await supabase
          .from('project_members')
          .select('*')
          .eq('user_email', email)
          .eq('project_id', projectId)
          .single();

        if (error || !data) {
          console.error('Error finding project member:', error?.message || 'No data found');
          return null;
        }

        this.$patch({
          user: null,
          userEmail: email,
          currentProject: { id: projectId, role: data.role },
        });

        return data;
      } catch (error) {
        console.error('Error checking project member:', error.message);
        return null;
      }
    },

    setCurrentProject(project) {
      this.currentProject = project;
      this.saveProjectToLocalStorage(project);
      if (project && project.id) {
        this.fetchContacts(project.id).catch((error) => {
          console.error('Error fetching contacts for the new project:', error.message);
        });
      }
    },

    clearCurrentProject() {
      this.currentProject = null;
      this.contacts = [];
    },

    async clearSession() {
      this.$patch({
        user: null,
        userEmail: null,
        currentProject: null,
        liveTimecode: '00:00:00',
        contacts: [],
        currentTimeSource: 'device',
        _intervalId: null,
        onlineStatus: navigator.onLine,
      });
      localStorage.removeItem('liveTimecode');
      localStorage.removeItem('currentTimeSource');
      localStorage.removeItem('userSession');
      localStorage.removeItem('currentProject');

      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }

      this.cleanupOnlineStatus();

      // Clear data from IndexedDB
      if (this.db) {
        try {
          await this.db.clear('session');
          await this.db.clear('project');
          await this.db.clear('contacts');
        } catch (error) {
          console.error('Error clearing IndexedDB:', error);
        }
      }
    },

    async signOut() {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) {
          throw new Error('Sign out failed');
        }
        await this.clearSession();
      } catch (error) {
        console.error('Error signing out:', error.message);
        throw error;
      }
    },

    setLiveTimecode(timecode) {
      this.liveTimecode = timecode;
      localStorage.setItem('liveTimecode', timecode);
    },

    incrementLiveTimecode() {
      let [hours, minutes, seconds] = this.liveTimecode.split(':').map(Number);
      seconds += 1;
      if (seconds >= 60) {
        seconds = 0;
        minutes += 1;
      }
      if (minutes >= 60) {
        minutes = 0;
        hours += 1;
      }
      this.setLiveTimecode(
        `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      );
    },

    async fetchContacts(projectId) {
      this.isLoading = true;
      try {
        if (navigator.onLine) {
          const { data, error } = await supabase
            .from('project_contacts')
            .select('*')
            .eq('project_id', projectId);

          if (error) throw error;

          this.contacts = data;
          
          // Update IndexedDB
          if (this.db) {
            const tx = this.db.transaction('contacts', 'readwrite');
            const store = tx.objectStore('contacts');
            await Promise.all(data.map(contact => store.put(contact)));
            await tx.done;
          }
        } else {
          // Fetch from IndexedDB when offline
          if (this.db) {
            this.contacts = await this.db.getAll('contacts');
          }
        }
      } catch (error) {
        console.error('Failed to fetch contacts:', error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async addContact(contact) {
      this.isSubmitting = true;
      try {
        const newContact = {
          project_id: this.currentProject.id,
          ...contact,
        };

        if (navigator.onLine) {
          const { data, error } = await supabase.from('project_contacts').insert([newContact]);
          if (error) throw error;
          this.contacts.push(data[0]);

          // Update IndexedDB
          if (this.db) {
            await this.db.put('contacts', data[0]);
          }
        } else {
          // Store in IndexedDB for later sync
          if (this.db) {
            const id = await this.db.put('contacts', newContact);
            this.contacts.push({ ...newContact, id });
          }
        }
      } catch (error) {
        console.error('Failed to add contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    async updateContact(contactId, updatedData) {
      this.isSubmitting = true;
      try {
        if (navigator.onLine) {
          const { data, error } = await supabase
            .from('project_contacts')
            .update(updatedData)
            .eq('id', contactId);

          if (error) throw error;

          const index = this.contacts.findIndex((c) => c.id === contactId);
          if (index !== -1 && data.length > 0) {
            this.contacts[index] = { ...this.contacts[index], ...data[0] };
          }

          // Update IndexedDB
          if (this.db) {
            await this.db.put('contacts', data[0]);
          }
        } else {
          // Update in IndexedDB for later sync
          if (this.db) {
            await this.db.put('contacts', { id: contactId, ...updatedData });
            
            const index = this.contacts.findIndex((c) => c.id === contactId);
            if (index !== -1) {
              this.contacts[index] = { ...this.contacts[index], ...updatedData };
            }
          }
        }
      } catch (error) {
        console.error('Failed to update contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    async deleteContact(contactId) {
      this.isSubmitting = true;
      try {
        if (navigator.onLine) {
          const { error } = await supabase
            .from('project_contacts')
            .delete()
            .eq('id', contactId);

          if (error) throw error;

          // Remove from IndexedDB
          if (this.db) {
            await this.db.delete('contacts', contactId);
          }
        } else {
          // Mark for deletion in IndexedDB
          if (this.db) {
            await this.db.put('contacts', { id: contactId, _deleted: true });
          }
        }

        this.contacts = this.contacts.filter((c) => c.id !== contactId);
      } catch (error) {
        console.error('Failed to delete contact:', error.message);
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },

    async syncOfflineData() {
      if (navigator.onLine && this.db) {
        const tx = this.db.transaction('contacts', 'readwrite');
        const store = tx.objectStore('contacts');

        for (const contact of await store.getAll()) {
          if (contact._deleted) {
            await supabase.from('project_contacts').delete().eq('id', contact.id);
            await store.delete(contact.id);
          } else if (!contact.synced) {
            const { data, error } = await supabase.from('project_contacts').upsert(contact);
            if (!error) {
              await store.put({ ...data[0], synced: true });
            }
          }
        }

        await tx.done;
        await this.fetchContacts(this.currentProject.id);
      }
    },

    initializeTimeSource() {
      const savedTimeSource = this.currentTimeSource || 'device';
      this.currentTimeSource = savedTimeSource;
      this.startTimeSource(this.currentTimeSource);
    },

    startTimeSource(source) {
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }

      switch (source) {
        case 'device':
          this.startDeviceTime();
          break;
        case 'custom':
          this.startCustomTimecode();
          break;
        case 'world':
          this.startWorldTime();
          break;
        default:
          this.startDeviceTime();
      }
    },

    setCurrentTimeSource(source) {
      if (!['device', 'custom', 'world'].includes(source)) {
        console.error(`Invalid time source: ${source}`);
        return;
      }
      this.currentTimeSource = source;
      localStorage.setItem('currentTimeSource', source);
      this.startTimeSource(source);
    },

    startDeviceTime() {
      const updateDeviceTime = () => {
        const now = new Date();
        const formattedTime = this.formatTimecode(
          now.getHours(),
          now.getMinutes(),
          now.getSeconds()
        );
        this.setLiveTimecode(formattedTime);
      };

      updateDeviceTime();
      this._intervalId = setInterval(updateDeviceTime, 1000);
    },

    startCustomTimecode(initialTime = '00:00:00') {
      if (this.liveTimecode === '00:00:00') {
        this.setLiveTimecode(initialTime);
      }

      this._intervalId = setInterval(() => {
        this.incrementLiveTimecode();
      }, 1000);
    },

    startWorldTime(offsetHours = 0) {
      const updateWorldTime = () => {
        const now = new Date();
        now.setUTCHours(now.getUTCHours() + offsetHours);
        const formattedTime = this.formatTimecode(
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        );
        this.setLiveTimecode(formattedTime);
      };

      updateWorldTime();
      this._intervalId = setInterval(updateWorldTime, 1000);
    },

    formatTimecode(hours, minutes, seconds) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
        seconds
      ).padStart(2, '0')}`;
    },

    cleanupTimecode() {
      if (this._intervalId) {
        clearInterval(this._intervalId);
        this._intervalId = null;
      }
    },

    resetLiveTimecode() {
      this.setLiveTimecode('00:00:00');
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    getUserEmail: (state) => state.userEmail,
    getCurrentProject: (state) => state.currentProject,
    getLiveTimecode: (state) => state.liveTimecode,
    getOnlineStatus: (state) => state.onlineStatus,

    getCurrentTimeSourceLabel: (state) => {
      switch (state.currentTimeSource) {
        case 'device':
          return 'Device Time';
        case 'custom':
          return 'Custom Timecode';
        case 'world':
          return 'World Time (GMT)';
        default:
          return 'Unknown';
      }
    },

    getContacts: (state) => state.contacts,
    getIsLoading: (state) => state.isLoading,
    getIsSubmitting: (state) => state.isSubmitting,
  },
});