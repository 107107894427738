<template>
<header v-if="!isHiddenRoute" class="header">
  <div :class="onlineStatusClass" class="status-indicator">
    {{ onlineStatusText }}
  </div>
  <div><router-link
    v-if="isAuthenticated && !isProjectsRoute"
    to="/projects"
    :class="{ active: isActiveRoute('/projects') }"
  >
    View All Projects
  </router-link></div>
  <nav>
    
    <button v-if="isAuthenticated" @click="handleSignOut" class="sign-out-button">
      Sign Out
    </button>
  </nav>
</header>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '../stores/userStore';

export default {
setup() {
  const userStore = useUserStore();
  const router = useRouter();
  const route = useRoute();

  const isAuthenticated = computed(() => userStore.isAuthenticated);

  const isHiddenRoute = computed(() => {
    const hiddenRoutes = ['/', '/auth/reset-password', '/auth/set-password'];
    return hiddenRoutes.includes(route.path);
  });

  const isProjectsRoute = computed(() => route.path === '/projects');

  const isOnline = ref(navigator.onLine);
  const onlineStatusText = computed(() => (isOnline.value ? 'Online' : 'Offline'));
  const onlineStatusClass = computed(() => (isOnline.value ? 'online-status' : 'offline-status'));

  const handleSignOut = async () => {
    try {
      await userStore.signOut();
      router.push('/');
    } catch (error) {
      console.error('Error during sign out:', error.message);
    }
  };

  const isActiveRoute = (path) => {
    return route.path.startsWith(path);
  };

  const updateOnlineStatus = () => {
    isOnline.value = navigator.onLine;
  };

  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  onUnmounted(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  });

  return {
    isAuthenticated,
    isHiddenRoute,
    isProjectsRoute,
    handleSignOut,
    isActiveRoute,
    onlineStatusText,
    onlineStatusClass,
  };
},
};
</script>

<style scoped>
.header {
  background-color: #ffffff;
  color: #333;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  margin: 0;
  font-size: 1.5em;
}

label {
  font-size: 0.8em;
  color: #666;
}

.status-indicator {
  font-size: 0.9em;
  padding: 5px 10px;
  border-radius: 5px;
}

.online-status {
  background-color: #d4edda;
  color: #155724;
}

.offline-status {
  background-color: #f8d7da;
  color: #721c24;
}

nav {
  display: flex;
  gap: 15px;
}

nav a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

nav a:hover, nav a.active {
  text-decoration: underline;
}

.sign-out-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #c82333;
}
</style>