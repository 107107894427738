<template>
<footer class="footer" v-if="!isHiddenRoute">
  <div class="container">
    <div class="footer-content">
      <!-- Left Column -->
      <div class="footer-column left-column">
        <h3 class="title">Live Timecode</h3>
        <p class="time-source-label">
          Current Time Source: {{ currentTimeSourceLabel }}
        </p>
        <p class="timecode">{{ liveTimecode }}</p>
        <TimeSourceSelector />
      </div>

      <!-- Middle Column -->
      <div class="footer-column middle-column">
        <div class="cache-usage">
          <h4 class="subtitle">Cache Usage</h4>
          <div class="usage-bar">
            <div class="usage-fill" :style="{ width: usagePercentage + '%' }"></div>
          </div>
          <p>{{ localStorageUsage.used }} / {{ localStorageUsage.max }} KB</p>
          <button @click="$emit('clearCache')" class="button clear-cache">
            Clear Cache
          </button>
        </div>
      </div>

      <!-- Right Column -->
      <div class="footer-column right-column">
        <div v-if="userEmail" class="user-info">
          <p>
            Logged in as: <strong>{{ userEmail }}</strong>
            <span v-if="isAdmin" class="admin-badge">Admin</span>
          </p>
          <button @click="$emit('signOut')" class="button sign-out">
            Sign Out
          </button>
        </div>
        <div v-else>
          <p>No user logged in</p>
        </div>

        <!-- Install PWA Button -->
        <div v-if="deferredPrompt" class="install-pwa">
          <button @click="installPWA" class="button install-button">
            Install App
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <p>&copy; {{ currentYear }} Soundrolling Notes App. All rights reserved.</p>
  </div>
</footer>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import TimeSourceSelector from './TimeSourceSelector.vue';

export default {
  components: {
    TimeSourceSelector,
  },
  props: {
    liveTimecode: {
      type: String,
      required: true,
    },
    currentTimeSourceLabel: {
      type: String,
      required: true,
    },
    localStorageUsage: {
      type: Object,
      required: true,
    },
    userEmail: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const deferredPrompt = ref(null);
    const currentYear = new Date().getFullYear();

    const isHiddenRoute = computed(() => {
      const hiddenRoutes = ['/', '/auth/reset-password', '/auth/set-password'];
      return hiddenRoutes.includes(route.path);
    });

    const usagePercentage = computed(() => {
      return (props.localStorageUsage.used / props.localStorageUsage.max) * 100;
    });

    const installPWA = async () => {
      if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        deferredPrompt.value = null;
      }
    };

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      deferredPrompt.value = e;
    };

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    });

    onUnmounted(() => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    });

    return {
      isHiddenRoute,
      deferredPrompt,
      installPWA,
      usagePercentage,
      currentYear,
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 30px 0 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer-column {
  flex: 1;
  min-width: 250px;
}

.title {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #3498db;
}

.subtitle {
  font-size: 1em;
  margin-bottom: 10px;
  color: #3498db;
}

.timecode {
  font-family: 'Courier New', monospace;
  font-size: 1.5em;
  margin: 10px 0;
}

.time-source-label {
  font-size: 0.9em;
  color: #bdc3c7;
}

.cache-usage {
  background-color: #34495e;
  padding: 15px;
  border-radius: 5px;
}

.usage-bar {
  height: 10px;
  background-color: #7f8c8d;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.usage-fill {
  height: 100%;
  background-color: #2ecc71;
  transition: width 0.3s ease;
}

.button {
  display: inline-block;
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-cache {
  background-color: #e74c3c;
  color: white;
}

.clear-cache:hover {
  background-color: #c0392b;
}

.sign-out {
  background-color: #3498db;
  color: white;
}

.sign-out:hover {
  background-color: #2980b9;
}

.install-button {
  background-color: #2ecc71;
  color: white;
}

.install-button:hover {
  background-color: #27ae60;
}

.user-info {
  background-color: #34495e;
  padding: 15px;
  border-radius: 5px;
}

.admin-badge {
  background-color: #f39c12;
  color: #2c3e50;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-left: 5px;
}

.footer-bottom {
  margin-top: 30px;
  background-color: #243342;
  padding: 10px 0;
  text-align: center;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    width: 100%;
  }
}
</style>